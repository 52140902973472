<template>
  <div class="header">
    <div class="menu">
      <button class="fold" @click="foldMenu"><i class="fa fa-navicon"></i></button>
    </div>
    <div class="search">
      <input v-model="searchVal" @input="search" type="text">
    </div>
    <div class="buttons">
      <button @click="addNote" class="add">
        <i class="fa fa-plus"></i> 添加
      </button>
<!--      <button @click="listMode" class="add">-->
<!--        <i class="fa fa-list-alt"></i> 列表-->
<!--      </button>-->
<!--      <button @click="exportImg" class="add">-->
<!--        <i class="fa fa-image"></i> 截图-->
<!--      </button>-->
<!--      <button @click="exportLogs" class="add">-->
<!--         导出-->
<!--      </button>-->
<!--      <button @click="importLogs" class="add">-->
<!--         导入-->
<!--      </button>-->

    </div>

  </div>
</template>

<script>
import { debounce, copyToClipbrd, decrypt, encrypt } from '@/utils/utils'
import html2canvas from 'html2canvas'

import md5 from 'md5'
import Toastify from 'toastify-js'
import localforage from "localforage"
export default {
  name: 'Header',
  data() {
    return {
      searchVal: ''
    }
  },
  methods: {
    foldMenu() {
      this.$emit('foldMenu')
    },
    addNote() {
      this.$emit('addNote')
    },
    listMode() {
      this.$emit('listMode')
    },
    exportImg() {
      // const ref = this.$refs.faultTree // 截图区域
      const mainDom = document.getElementById('main')
      this.tmpWidth = mainDom.style.width
      this.tmpHeight = mainDom.style.height

      // const copyDom = mainDom.cloneNode(true)
      mainDom.style.width = mainDom.scrollWidth + "px"
      mainDom.style.height = mainDom.scrollHeight + "px"

      html2canvas(mainDom, { backgroundColor: '#f4f4f4' }) //
        .then(canvas => {
          const dataURL = canvas.toDataURL('image/png')
          this.dataURL = dataURL
          const creatDom = document.createElement('a')
          document.body.appendChild(creatDom)
          creatDom.href = dataURL
          creatDom.download = '笔记导出截图'
          creatDom.click()
        })
      mainDom.style.width = this.tmpWidth
      mainDom.style.height = this.tmpHeight
    },
    /**
     * 导出日志
     */
    async exportLogs({ id }) {
      let NoteLogsStrEncripy = '"[]"'
      let NoteLogsStr = '"[]"'

      // if(localStorage.getItem('NoteLogs')) {
      //   NoteLogsStrEncripy = localStorage.getItem('NoteLogs')
      //   NoteLogsStr = decrypt(NoteLogsStrEncripy)
      //
      // }
      // let NoteLogs = JSON.parse(NoteLogsStr)

      let NoteLogs = await localforage.getItem('NoteLogs')
      NoteLogsStr = JSON.stringify(NoteLogs)
      console.log(NoteLogsStr)
      NoteLogsStrEncripy = encrypt(NoteLogsStr)

      console.log("exportLogs", NoteLogs)

      // 这里可以进行筛选，例如只导出一部分id的笔记日志，这样日志的体积会变小
      if(id) {
        NoteLogs = NoteLogs.filter(noteLog => {
          // 如果笔记的id和传入的id相等，那么进行日志的导出
          if(noteLog.data.note && noteLog.data.note.id === id) {
            console.log(noteLog)

            return true
          } else {
            return false
          }
        })
        // 因为数据进行了过滤操作，所以字符串和加密字符串都进行了修改，这里就要重新进行加密操作，才能保证通过导入的校验
        NoteLogsStr = JSON.stringify(NoteLogs)
        console.log("没有加密的字符串", NoteLogsStr)
        NoteLogsStrEncripy = encrypt(NoteLogsStr)
      }

      const exprotNoteLogs = {
        tips: "你好，你现在所看到的就是你所有的笔记数据，只需要保存好，你的笔记就不会丢失，切记不要更改任何内容。只需要复制一下，然后点击导入就可以在任何浏览器中复现你的笔记",
        createAt: new Date().getTime(),
        logNum: NoteLogs.length,
        logMd5: md5(NoteLogsStr), // md5保存的是一次解密后的字符串的md5
        NoteLogs: NoteLogsStrEncripy // 保存的是加密的字符串
      }
      console.log("exprotNoteLogs", exprotNoteLogs)
      const exportNoteLogsStr = JSON.stringify(exprotNoteLogs)
      console.log("exprotNoteLogs-变为文本", exprotNoteLogs)
      const toastElement = Toastify({
        text: '日志复制到剪切板，请携带剪切板信息去导入',
        gravity: 'bottom',
        position: 'right',
        duration: 10000,
        close: false,
        backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
      })
      console.log("拷贝剪切板")
      copyToClipbrd(exportNoteLogsStr)
      toastElement.showToast()
    },
    /**
     * 导入日志
     * @returns {Promise<void>}
     */
    async importLogs() {
      const clipboard = navigator.clipboard
      let clipText = ''
      // 读剪切板
      try {
        clipText = await navigator.clipboard.readText()

      } catch (e) {

        clipText = prompt("请粘贴导出的内容，建议开启剪切板读取权限，导入更方便")
        console.log(clipText)

        // const toastElement = Toastify({
        //   text: '开启读剪切板权限',
        //   gravity: 'bottom',
        //   position: 'right',
        //   duration: 2000,
        //   close: false,
        //   backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
        // })
        // toastElement.showToast()
        // return
      }
      // 判断是否为json数据
      let NoteLogsData = {}
      try {
        NoteLogsData = JSON.parse(clipText)

      } catch (e) {
        console.warn(e)
        const toastElement = Toastify({
          text: '请确保剪切板的内容为导出的数据',
          gravity: 'bottom',
          position: 'right',
          duration: 5000,
          close: false,
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
        })
        toastElement.showToast()
        return
      }
      // 判断是否为需要的格式
      let NoteLogs = NoteLogsData.NoteLogs
      if(!NoteLogs) {
        const toastElement = Toastify({
          text: '请确保剪切板的内容为导出的数据',
          gravity: 'bottom',
          position: 'right',
          duration: 5000,
          close: false,
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
        })
        toastElement.showToast()
        return
      }

      // NoteLogs 是加密后的字符串
      try {
        NoteLogs = decrypt(NoteLogs)
      } catch (e) {

      }
      console.log(NoteLogs)

      if(NoteLogsData.logMd5 !== md5(NoteLogs)) {
        const toastElement = Toastify({
          text: '数据损坏，请重新导出，不要自己修改导出的数据 md5校验失败',
          gravity: 'bottom',
          position: 'right',
          duration: 5000,
          close: false,
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
        })
        toastElement.showToast()
        return
      }
      NoteLogs = JSON.parse(NoteLogs)

      if(NoteLogsData.logNum !== NoteLogs.length) {
        const toastElement = Toastify({
          text: '数据损坏，请重新导出，不要自己修改导出的数据 长度不匹配',
          gravity: 'bottom',
          position: 'right',
          duration: 5000,
          close: false,
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
        })
        toastElement.showToast()
        return
      }

      // localStorage.setItem("NoteLogs", JSON.stringify(NoteLogs)) // 直接覆盖，不太对，需要改成追加

      // 当前日志
      let oldNoteLogs = await localforage.getItem('NoteLogs')
      // if(localStorage.getItem("NoteLogs")) {
      //   oldNoteLogs = decrypt(localStorage.getItem("NoteLogs"))
      //   oldNoteLogs = JSON.parse(oldNoteLogs)
      // }
      const oldLength = oldNoteLogs.length
      const createAtList = oldNoteLogs.map(item => item.createAt)
      console.log(createAtList)
      for(const item of NoteLogs) {
        if(!createAtList.includes(item.createAt)) {
          oldNoteLogs.push(item)
        }
      }
      oldNoteLogs = oldNoteLogs.sort((a, b) => a.createAt - b.createAt)
      // localStorage.setItem("NoteLogs", encrypt(JSON.stringify(oldNoteLogs)))

      localforage.setItem('NoteLogs', oldNoteLogs).then(function (value) {
        // 当值被存储后，可执行其他操作
        console.log("localforage保存成功")
      }).catch(function(err) {
        // 当出错时，此处代码运行
        console.log(err)
      })

      const toastElement = Toastify({
        text: `原始共${oldLength}条日志, 本次导入日志总共${NoteLogsData.logNum}条，导入成功${oldNoteLogs.length - oldLength}条`,
        gravity: 'bottom',
        position: 'right',
        duration: 10000,
        close: false,
        backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
      })
      toastElement.showToast()
      this.$parent.saveNotes()

    },
    search() {
      debounce(() => {
        // console.log("输入的内容", this.searchVal)
        this.$emit('search', this.searchVal)
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  height: 50px;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fbfbfb; // #fbfbfb rgb(41, 149, 191)
  justify-content: space-evenly;
  .menu {
    display: flex;
    align-items: center;
    max-width: 500px;
    width: 50px;
    position: relative;
    .fold {
      background-color: transparent;
      position: fixed;
      left: 0;
      border: 0;
      width: 50px;
      height: 50px;
      font-size: 24px;
      cursor: pointer;
    }
  }
  .search {
    //border: 1px solid red;
    display: flex;
    align-items: center;
    width: 500px;
    //flex: 1;
    input {
      width: 100%;
      height: 30px;
      border-radius: 15px;
      padding-left: 15px;
      background-color: #dcdcdc;
      //outline: none;
      border: 0;
      &:focus, &:active {
        //border: 0;
        outline: none;
      }
    }

  }
  .buttons {
    max-width: 500px;
    //border: 1px solid red;
    display: flex;
    align-items: center;
    .add {
      //background-color: transparent;
      flex-shrink: 0;
      border: 0;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      cursor: pointer;
      height: 30px;
      width: 60px;
      border-radius: 15px;
      background-color: #f5f5f5;
      &:hover {
        background-color: #dcdcdc; // #f5f5f5
      }
    }
  }

}
</style>
