<!--
笔记卡片抽离出来的组件
用法：
          <NoteCard
            class="note-card"
            v-for="item in column.columnArr"
            :item="item" :key="item.id"
            @editNote="edit"
            @tagClick="tagClick"
            @exportOneNoteLog="exportOneNoteLog"
            @reviewNote="reviewNote"
          ></NoteCard>
样式调整
        .note-card {
          border-radius: 4px;
          width: 14rem;
          user-select: none;
          cursor: pointer;
        }
-->
<template>
  <div
    class="item"
    :class="{secret: item.secret, hidden: item.postStatus === 'hidden', public: item.postStatus === 'publish'}"
    @click="$store.state.setting.leftEdit ? edit(item, $event) : reviewNote(item)"
    @contextmenu.prevent="$store.state.leftEdit ? edit(item, $event) : reviewNote(item)"
    :id="'note_' + item.id"
    :style="{  background: item.background, color: item.color }"
  >
<!--    <div class="text" v-html="renderMarkdownDeal(showContent(this.item))"></div>-->
    <MarkdownRender :value="showContent(this.item)" :fold="false"></MarkdownRender> <!-- :fold="false" 取消内容折叠-->
    <div class="tags">
      <div class="tag" @click.stop="tagClick(tagName)" :title="tagName" v-for="(tagOption, tagName) in item.tags" :key="tagName" :style="{background: tagOption.background, color: tagOption.color }">{{ tagName }}</div>
      <!--              {{ item.height }}-->
    </div>
    <div class="tools">
      <button @click.stop="deleteNote(item)" title="删除"><i class="fa fa-trash"></i></button>
      <button @click.stop="copyNote(item)" title="复制内容"><i class="fa fa-clipboard"></i></button>
      <button @click.stop="undoNote(item)" title="还原" v-if="item.state === 1"><i class="fa fa-undo"></i></button>
<!--      <button @click.stop="exportOneNoteLog(item)" title="导出日志"><i class="fa fa-share"></i></button>-->
      <button @click.stop="shareUrl(item)" title="分享链接"><i class="fa fa-share"></i></button>
      <button @click.stop="secretOneNoteLog(item)" v-if="!item.secret" title="隐藏笔记"><i class="fa fa-user-secret"></i></button>
      <button @click.stop="publicOneNoteLog(item)" v-if="item.secret" title="取消隐藏"><i class="fa fa-users"></i></button>
      <button v-if="!$store.state.leftEdit" @click.stop="reviewNote(item)" title="查看"><i class="fa fa-eye"></i></button>
      <button v-if="$store.state.leftEdit" @click.stop="edit(item, $event)" title="编辑"><i class="fa fa-edit"></i></button>

      <!--              {{item.state}}-->
    </div>
  </div>
</template>

<script>

import hljs from 'highlight.js'
import { copyToClipbrd, showToastError, showToastSuccess } from '@/utils/utils'
import Toastify from 'toastify-js'
import logMixin from '@/mixin/logMixin'
import markedMixin from '@/mixin/markedMixin'
import typechoMixin from "@/mixin/typechoMixin"
import MarkdownRender from "@/components/MarkdownRender/index.vue"

export default {
  name: 'NoteCard',
  components: { MarkdownRender },
  mixins: [logMixin, markedMixin, typechoMixin],
  props: {
    item: {
      type: Object
    },
    option: {
      type: Object
    }
  },
  watch: {
    "item.content": function(val) {
      // 当内容改变以后，刷新一些样式更新操作
      this.$nextTick(() => {
        this.refresh()
      })
    }
  },
  mounted () {
    this.noteAddFlow()
  },
  methods: {
    showContent(note) {
      if(note.title && note.title !== '未命名') {
        return '# ' + note.title + '\n\n' + note.content
      }
      return note.content
    },
    /**
     * 给笔记添加代码浮动的事件
     */
    noteAddFlow() {
      if(!this.$store.state.setting.codeFlow) return // 不开启代码浮动
      const textEl = document.querySelector(`#note_${this.item.id} > .text`)
      const pres = textEl.getElementsByTagName('pre')

      for(const pre of pres) {
        const code = pre.getElementsByTagName('code')[0]
        const that = this

        code.addEventListener('mouseenter', function () {
          if(!that.$store.state.setting.codeFlow) return
          // 防止代码块一直弹出，只有鼠标移动上去2秒的时候才会浮出代码块
          that[`timeout_${that.item.id}`] = setTimeout(() => {
            // 计算一下屏幕宽度，然后看看这个code浮出来以后，会不会超过最右边，如果超过了，就需要减掉对应的宽度
            const appEl = document.getElementById('app') // 整个项目的root
            const mainEl = document.getElementById('main')
            // console.log("main left", mainEl.offsetLeft)
            const cWidth = appEl.offsetWidth // 获取整个屏幕的宽度
            const note = document.querySelector(`#note_${that.item.id} > .text`) // 笔记的dom
            const left = note.offsetLeft // 笔记的左边
            // 如果这个卡片的宽度比main的宽度还宽的话，直接不进行浮动显示
            if(this.scrollWidth >= cWidth - 20) {
              return
            }

            // console.log(left, this.scrollWidth, cWidth)
            // 如果这个浮出来的卡片的宽度大于屏幕的话，往左移动一点
            // 这个卡片左边的距离 + 卡片弹出来后的宽度 >= 整个页面的宽度
            if(left + this.scrollWidth >= cWidth - 40) {
              // 如果这个卡片经过计算以后，会影响左边的显示，直接不浮动
              // 卡片的左边 - 需要偏移的 < main的左边
              if(left - (left + this.scrollWidth - (cWidth - 40)) < mainEl.offsetLeft) {
                return
              }

              // console.log("偏移计算", left + this.scrollWidth - (cWidth - 40))
              this.style.transform = `translateX(-${left + this.scrollWidth - (cWidth - 40)}px)`
            }

            pre.style.height = this.offsetHeight + "px"
            this.classList.add('flow')
          }, 2000)
        })
        // 鼠标移动代码的滚动条以后也清除定时器
        code.addEventListener('scroll', function () {
          clearTimeout(that[`timeout_${that.item.id}`])
        })
        // 鼠标点击代码以后，清除定时器，目前这么解决
        code.addEventListener('click', function() {
          clearTimeout(that[`timeout_${that.item.id}`])
          this.style.transform = "translateX(0)"
          this.classList.remove('flow')
        })
        // 鼠标离开以后清除代码浮动
        code.addEventListener('mouseleave', function() {
          clearTimeout(that[`timeout_${that.item.id}`])
          this.style.transform = "translateX(0)"
          this.classList.remove('flow')
        })
      }
    },
    /**
     * 给笔记添加思维导图浮动
     * todo: 有很多bug，目前没有加入
     */
    noteAddFlowMind() {
      const textEl = document.querySelector(`#note_${this.item.id} > .text`)
      const mindContainers = textEl.getElementsByClassName('mind-container')

      console.log("happen->mindContainers", mindContainers)

      for(const mindContainer of mindContainers) {
        const mind = mindContainer.getElementsByClassName('mind')[0]
        const that = this
        mind.addEventListener('mouseenter', function () {
          that[`timeout_mind_${that.item.id}`] = setTimeout(() => {
            // 计算一下屏幕宽度，然后看看这个code浮出来以后，会不会超过最右边，如果超过了，就需要减掉对应的宽度
            const appEl = document.getElementById('app') // 整个项目的root
            const mainEl = document.getElementById('main')
            // console.log("main left", mainEl.offsetLeft)
            const cWidth = appEl.offsetWidth // 获取整个屏幕的宽度
            const note = document.querySelector(`#note_${that.item.id} > .text`) // 笔记的dom
            const left = note.offsetLeft // 笔记的左边

            const flowMindWidth = 800
            // 如果弹出来的mind的宽度(500)比main的宽度还宽的话，直接不进行浮动显示
            if(cWidth - 20 <= flowMindWidth) {
              return
            }

            // console.log(left, this.scrollWidth, cWidth)
            // 如果这个浮出来的卡片的宽度大于屏幕的话，往左移动一点
            // 这个卡片左边的距离 + 卡片弹出来后的宽度 >= 整个页面的宽度
            if(left + flowMindWidth >= cWidth - 40) {
              // 如果这个卡片经过计算以后，会影响左边的显示，直接不浮动
              // 卡片的左边 - 需要偏移的 < main的左边
              if(left - (left + flowMindWidth - (cWidth - 40)) < mainEl.offsetLeft) {
                return
              }

              // console.log("偏移计算", left + this.scrollWidth - (cWidth - 40))
              this.style.transform = `translateX(-${left + flowMindWidth - (cWidth - 40)}px)`
            }

            mindContainer.style.height = "500px"
            this.classList.add('flow')

            that.renderOneMind(this, true)

          }, 1000)

        })

        // 鼠标移动代码的滚动条以后也清除定时器
        mind.addEventListener('scroll', function () {
          clearTimeout(that[`timeout_mind_${that.item.id}`])
          if(mindContainer.style.height) {
            mindContainer.style.height = null
            that.renderOneMind(this, true)
          }
        })
        // 鼠标点击代码以后，清除定时器，目前这么解决
        mind.addEventListener('click', function() {
          clearTimeout(that[`timeout_mind_${that.item.id}`])
          this.style.transform = "translateX(0)"
          this.classList.remove('flow')
          if(mindContainer.style.height) {
            mindContainer.style.height = null
            that.renderOneMind(this, true)
          }
        })
        // 鼠标离开以后清除代码浮动
        mind.addEventListener('mouseleave', function() {
          clearTimeout(that[`timeout_mind_${that.item.id}`])
          this.style.transform = "translateX(0)"
          this.classList.remove('flow')
          if(mindContainer.style.height) {
            mindContainer.style.height = null
            that.renderOneMind(this, true)
          }
        })
      }
    },
    exportOneNoteLog(item) {
      this.$emit('exportOneNoteLog', item)
    },
    reviewNote(item) {
      this.$emit('reviewNote', item)
    },
    shareUrl(item) {
      copyToClipbrd(item.full_url)
      if (item.full_url) {
        if(item.postStatus === 'private') {
          showToastError("拷贝成功，但是别人无法访问")
        } else {
          showToastSuccess("拷贝成功")
        }
      } else {
        showToastError("无法获取url")
      }
    },
    /**
     * 取消隐藏笔记
     * @param item
     */
    publicOneNoteLog(item) {
      item.secret = false
      this.addLog({ option: 'EditNote', note: { id: item.id, secret: item.secret } })
      this.$emit('saveNotes')
    },
    /**
     * 隐藏一个笔记
     */
    secretOneNoteLog(item) {
      item.secret = true
      this.addLog({ option: 'EditNote', note: { id: item.id, secret: item.secret } })
      this.$emit('saveNotes')
    },
    /**
     * 删除笔记
     * @param item
     */
    deleteNote(item) {
      //  v-if="item.state !== 1"
      if(item.state === 0) {
        item.state = 1
        // this.addLog({ option: 'EditNote', note: { id: item.id, state: item.state } })
        this.$emit('saveNotes')
      } else {
        console.log("彻底删除", item)
        this.$store.state.contentArr = this.$store.state.contentArr.filter(item2 => item2.id !== item.id)
        this.deleteNoteTypecho(item.id)
        this.$emit('saveNotes')
      }

    },
    /**
     * 恢复笔记
     */
    undoNote(item) {
      item.state = 0
      this.addLog({ option: 'EditNote', note: { id: item.id, state: item.state } })
      this.$emit('saveNotes')
    },
    /**
     * 复制笔记内容
     * @param item
     */
    copyNote(item) {
      copyToClipbrd(item.content)

      const toastElement = Toastify({
        text: '笔记内容已复制',
        gravity: 'bottom',
        position: 'right',
        duration: 1000,
        close: false,
        backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
      })
      toastElement.showToast()
    },
    /**
     * 标签点击
     */
    tagClick(tagName) {
      this.$emit('tagClick', tagName)
    },

    /**
     * 编辑笔记
     * @param item
     */
    edit(item, event) {
      if (event.path) {
        for(const path of event.path) {
          if(path.tagName === 'svg') return // 如果是svg思维导图，则取消点击编辑事件
        }
      }
      this.$emit('editNote', item)
    },
    /**
     * 刷新笔记内容
     */
    refresh() {
      this.renderMind()
      this.noteAddFlow()
    }
  }
}
</script>

<style scoped lang="scss">
// 卡片框
.item {
  // 选择每个column的最后一个卡片
  &:last-child {
    //padding-bottom: 100px;
    //height: 500px;
  }
  &.secret { // 私密笔记样式
    box-shadow: 0 0 10px rgba(102, 51, 153, 0.9);
  }
  &.secret:hover {
    box-shadow: 0 0 20px rgba(102, 51, 153, 0.9);
  }

  &.hidden { // 公开笔记样式
    box-shadow: 0 0 2px rgba(70, 153, 51, 0.5);
  }

  &.hidden:hover { // 公开笔记样式
    box-shadow: 0 0 2px rgba(70, 153, 51, 0.9);
  }

  &.public { // 公开笔记样式
    //box-shadow: 0 0 2px rgba(70, 153, 51, 0.5);
    box-shadow: 0 0 2px rgba(222, 29, 11, 0.5);
  }

  &.public:hover { // 公开笔记样式
    //box-shadow: 0 0 2px rgba(70, 153, 51, 0.9);
    box-shadow: 0 0 2px rgba(222, 29, 11, 0.9);
  }

  //width: 14rem;
  color: #333;
  //border-radius: 4px;
  margin: 10px 5px;
  padding: 10px;
  transition: height 0.5s ease-in-out; // height

  //user-select: none;
  //cursor: pointer;
  //textarea {
  //  background-color: transparent;
  //  border: 0;
  //  resize: none;
  //}
  /* todo 用scss的混合 mixins 可以将这个代码抽离出来 */
  .text {
    font-size: 14px;
    color: #333;
    font-family: "Microsoft YaHei";
    word-break: break-word;
    //overflow: hidden;
    position: relative;

    // 思维导图外面的框
    :deep .mind-container {
      display: inline-block;
    }

    :deep .mind {
      width: 100%;
      &:hover {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.17);
      }
      // 思维导图浮动样式，代码目前没有用
      &.flow {
        z-index: 1;
        background-color: white;
        padding: 30px;
        width: 800px;
        height: 500px;
        border-radius: 5px;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.17);
        position: absolute;
      }
    }

    // 带有chekbox的li
    :deep .note-checkbox {
      list-style: none;
      //transform: translateX(-15px);
      //padding-left: 4px;
      //display: flex;
      //align-items: center;

      input[type=checkbox] {
        //transform: translateX(-19px);
        //transform: translateY(1.5px);
        -webkit-appearance: none;
        //-moz-appearance: none;
        position: relative;
        right: 0;
        bottom: 0;
        left: 0;
        height: 1.25rem;
        width: 1.25rem;
        transition: 0.2s;
        color: #fff;
        cursor: pointer;
        margin: 0.4rem 0 0.4rem;
        margin-right: 0.3rem;
        outline: 0;
        border-radius: 0.1875rem;
        vertical-align: -0.65rem;
        border: 2px solid #2f4154;

        &:after, &:before {
          content: " ";
          transition: 0.2s;
          position: absolute;
          background: #fff;
        }
        &:after {
          right: 0.5625rem;
          bottom: 0.1875rem;
          width: 0.125rem;
          height: 0;
          -webkit-transform: rotate(40deg);
          -moz-transform: rotate(40deg);
          -ms-transform: rotate(40deg);
          -o-transform: rotate(40deg);
          transform: rotate(40deg);
          transition-delay: 0.2s;
        }
        &:before {
          left: 0.125rem;
          top: 0.375rem;
          width: 0;
          height: 0.125rem;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:checked {
          background: #2f4154;
          //margin-right: 0.5rem;
          &:before {
            left: 0.0625rem;
            top: 0.625rem;
            width: 0.375rem;
            height: 0.125rem;
          }
          &:after {
            right: 0.3125rem;
            bottom: 0.0625rem;
            width: 0.125rem;
            height: 0.875rem;
          }
        }
      }
    }

    :deep img {
      width: 100%;
      border-radius: 5px;
    }
    :deep pre {
      font-size: 10px;
      padding: 0;
      margin: 0;

    }

    :deep code {
      display: block;
      overflow-x: auto;
      padding: 1em;
      // 添加一个阴影的变化
      transition: all 0.1s ease-in-out, box-shadow 2.3s cubic-bezier(0, 0, 0, 1.59); // height
      &::-webkit-scrollbar-thumb {
        background-color: transparent;

      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.3);
        }
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.17);
      }
      // 代码浮动样式
      &.flow {
        z-index: 1;
        padding-right: 20px;
        border-radius: 5px;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.17);
        position: absolute;
      }
    }

    :deep ol, :deep ul, :deep dl {
      //list-style: none;
      //margin: 0;
      padding-left: 0;
      list-style-position: inside;

      li {
        margin-bottom: 0.5rem;
      }
    }

    :deep p {
      //display: inline-block;
      //word-break: ;
    }

    :deep .more {
      color: #cecece;
      width: 100%;
      display: inline-block;
      text-align: left;

    }
    :deep del {
      background-color: rgba(88, 179, 193, 0.03);
      color: transparent;
      text-decoration: none;
      &:hover {
        background-color: transparent;
        color: var(--color-card-h2-text);
      }
    }

    :deep a {
      text-decoration: none;
      //color: inherit;
      //color: rgba(96, 183, 215, 0.51);
      //color: rgba(206, 206, 206, 0.6);
      color: var(--color-card-a-text);
      &:hover {
        //background-color: #efefef;
        text-decoration: underline;
      }
    }
    /* todo 随便兼容了一下，正常卡片里面不用h1和h2，因为卡片要轻，h1和h2太大了
    很多卡片结合成一篇文章的时候可以用h1或者h2来当标题 */
    :deep h1 {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 1.2rem;
      color: var(--color-card-h1-text);
      //padding-left: 5px;
      //border-bottom: 2px solid rgb(88 179 193 / 69%);
      //border-left: 5px solid var(--color-card-h3-border);
      padding-bottom: 5px;
      border-bottom: 3px solid var(--color-card-h2-border);
      //text-align: center; /* 添加这行来使标题居中 */
    }

    :deep h2 {
      margin-top: 0;
      font-size: 1.1rem;
      color: var(--color-card-h2-text);
      padding-left: 5px;
      border-left: 4px solid var(--color-card-h3-border);
      //padding-bottom: 5px;
      //border-bottom: 2px solid var(--color-card-h2-border);
    }

    :deep h3 {
      font-size: 1.0rem;
      color: var(--color-card-h3-text);
      padding-left: 7px;
      //border-bottom: 2px solid rgb(88 179 193 / 69%);
      border-left: 2px solid var(--color-card-h3-border);
      margin: 0
    }

    :deep blockquote {
      margin: 0;
      padding-left: 10px;
      border-left: 2px solid var(--color-card-blockquote-border);
      background: var(--color-card-blockquote-bg);
    }
  }
  //position: relative;
  .tags {
    display: flex;
    flex-wrap: wrap;
    .tag {
      //height: 20px;
      margin-bottom: 2px;
      display: flex;
      //align-content: center;

      align-items: center;
      //height: 20px;
      font-size: 10px;
      padding: 0 8px;
      text-align: center;
      line-height: 20px;
      border-radius: 10px;
      margin-right: 2px;
      max-width: 90px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .tools {
    transition: all 0.2s ease-in;
    opacity: 0;
    //border: 1px red solid;
    //position: absolute;
    transform: translateX(-8px);

    button {
      background-color: transparent;
      cursor: pointer;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      border: 0;
      margin-right: 10px;
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
  &:hover .tools {
    opacity: 1;
  }

  &:hover {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.17);
    &:deep del {
      background-color: rgba(88, 179, 193, 0.2);
    }
  }
}
</style>
