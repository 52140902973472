<template>
  <div class="menu">
    <div class="buttons">
      <button @click="changeFilter([0])" :class="{active: active === 0}">笔记</button>
<!--      <button @click="changeFilter([2])" :class="{active: active === 2}">已归档</button>-->
      <button @click="changeFilter([1])" :class="{active: active === 1}">已删除</button>
    </div>
    <div class="show-filter-tags">
      <div class="filterTags" @click="clearFilterRulesIncludeTags">
        <div class="tag" v-show="filterRules.includeTags.length">
          包含: <span v-for="tag of filterRules.includeTags" :key="tag">{{ tag }} </span>
        </div>
        <div class="clear" v-show="filterRules.includeTags.length">
          <button><i class="fa fa-close"></i></button>
        </div>
      </div>
      <div class="filterTags" @click="clearFilterRulesTags">
        <div class="tag" v-show="filterRules.tags.length">
          只包含: <span v-for="tag of filterRules.tags" :key="tag">{{ tag }} </span>
        </div>
        <div class="clear" v-show="filterRules.tags.length">
          <button><i class="fa fa-close"></i></button>
        </div>
      </div>
    </div>

    <div class="all-tags">
      <span class="tag" v-for="(tagOption, index) of allTagsData" :key="index" @click="changeFilterIncludeTags(tagOption.tagName)" :class="{active: tagOption.active}" :style="{background: tagOption.background, color: tagOption.color }"><span class="tag-name">{{ tagOption.tagName }}</span><span class="number">{{ tagOption.count }}</span></span>
    </div>

    <div class="footer">
      <div class="setting">
        <span class="logo">
          <i class="fa fa-cog" @click="$parent.settingShow=true"></i>
        </span>
        <span class="setting-item">
          <i title="点击空白隐藏【只包含】标签" class="toggle fa" @click="switchFilterRulesTags" :class="$store.state.setting.clearFilterRulesTags ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
        </span>
        <span class="setting-item">
          <i title="点击空白隐藏【包含】标签" class="toggle fa" @click="switchFilterRulesIncludeTags" :class="$store.state.setting.clearFilterRulesIncludeTags ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
        </span>
      </div>
      <span class="secret">
        <i class="fa fa-random"></i> <i class="secret-toggle fa" @click="toggleShuffle" :class="$parent.filterRules.shuffle ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
      </span>
      <span class="secret">
        <i class="fa fa-user-secret"></i> <i class="secret-toggle fa" @click="toggleSecret" :class="$parent.filterRules.secret ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
      </span>
      <span class="secret">
        <!-- 当然，这里的代码可以删除，这样就可以在设置里面进行配置的更改了，毕竟这里的修改不会真的保存，但是这个可以临时修改一下，还是很方便的-->
        <i class="fa fa-hand-paper-o" title="代码浮动"></i> <i class="secret-toggle fa" @click="toggleCodeFlow" title="代码浮动" :class="$store.state.setting.codeFlow ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
      </span>
<!--      <span class="text">日志数量:{{ $store.state.logNum }}</span>-->
      <span class="text">笔记数量:{{ $parent.renderNumber }}/{{ $parent.notesList.length }}</span>
      <span class="text">当前版本: {{ nowVersion }}</span>
      <span class="text" v-show="subTime > 0.1" style="color: #f37676;">最新版本: {{ lastUpdated }}</span>
      <span class="text" v-if="updateLogsOpen">同步状态:{{logState}}<i v-show="logState === '同步成功'" @click="pullNoteLog" class="fa fa-refresh pull-log" :class="{'fa-spin': $store.state.pullNoteLogFlag}"></i></span>
    </div>
  </div>
</template>

<script>
import saveLoadMixin from '@/mixin/saveLoadMixin'
import logMixin from '@/mixin/logMixin'
import { timeFormat } from "@/utils/utils"
import { now } from "d3-timer"

export default {
  name: 'Menu',
  props: ['active', 'filterRules', 'allTags'],
  mixins: [saveLoadMixin, logMixin],
  data() {
    return {
      pullNoteLogFlag: false,
      lastUpdated: '',
      nowVersion: '',
      subTime: 0,
      // allTags: {
      //   '你好': { background: '#5c6bc0', color: '#fff' }
      // }
    }
  },
  mounted() {
    this.nowVersion = timeFormat(window.versionTime).split(' ')[0]
    this.fetchLatestUpdate()
  },
  computed: {
    updateLogsOpen() {
      return this.$store.state.setting.updateLogsOpen
    },
    logState() {
      if(this.$store.state.updateLogState === 0) {
        return `${(this.$store.state.updateLogsIndex / (this.$store.state.logNum - 1) * 100).toFixed(2)} %`
      } else if(this.$store.state.updateLogState === 1) {
        return '同步成功'
      } else if(this.$store.state.updateLogState === 2) {
        return '连接失败'
      } else if(this.$store.state.updateLogState === 3) {
        return '密码错误'
      }
      return '无状态'
    },
    allTagsData() {
      const tags = this.allTags
      tags.forEach(item => {
        if(this.filterRules.includeTags.includes(item.tagName)) {
          item.active = 1
        }
      })

      return tags
    }
  },
  methods: {
    async fetchLatestUpdate() {
      const axios = require('axios')
      try {
        // 替换为你的 Docker Hub API 请求
        const response = await axios.get('/typecho-api/dockerhub');
        const lastUpdated = response.data.last_updated
        const lastUpdated_timestamp = new Date(lastUpdated).getTime();

        this.subTime = (lastUpdated_timestamp - window.versionTime) / 1000 / 60 / 60 / 24
        console.log(this.subTime)
        // console.log(window.versionTime, timestamp, this.subTime / 1000 / 60 / 60 / 24)

        this.lastUpdated = timeFormat(lastUpdated_timestamp).split(' ')[0]

      } catch (error) {
        console.error('获取更新信息失败:', error);
      }
    },
    changeFilter(val) {
      this.$emit('changeFilterRulesState', val)
    },
    toggleSecret() {
      this.$parent.filterRules.secret = !this.$parent.filterRules.secret
      this.$parent.init()
    },
    toggleCodeFlow() {
      this.$store.state.setting.codeFlow = !this.$store.state.setting.codeFlow
      this.$parent.init()
    },
    toggleShuffle() {
      this.$parent.filterRules.shuffle = !this.$parent.filterRules.shuffle
      this.$parent.init()
    },
    switchFilterRulesTags() {
      this.$store.state.setting.clearFilterRulesTags = !this.$store.state.setting.clearFilterRulesTags
      this.saveSetting()
    },
    switchFilterRulesIncludeTags() {
      this.$store.state.setting.clearFilterRulesIncludeTags = !this.$store.state.setting.clearFilterRulesIncludeTags
      this.saveSetting()
    },
    /**
     * 删除过滤器中的标签
     */
    clearFilterRulesTags() {
      this.$emit('clearFilterRulesTags')
    },
    clearFilterRulesIncludeTags() {
      this.$emit('clearFilterRulesIncludeTags')
    },
    changeFilterIncludeTags(tagName) {
      const tagSet = new Set(this.$parent.filterRules.includeTags)
      if(tagSet.has(tagName)) {
        tagSet.delete(tagName)
      } else {
        tagSet.add(tagName)
      }
      this.$parent.filterRules.includeTags = [...tagSet]
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped lang="scss">
.menu {
  user-select: none;
  padding-top: 50px; /* 抵消头部菜单 */
  flex-shrink: 0; /* 防止压缩 */
  //background-color: #fbfbfb; // #fbfbfb rgb(43, 215, 181)
  width: 150px;
  display: flex;
  height: 100vh;
  flex-direction: column;
  //justify-content: space-between;

  .buttons {
    display: flex;
    flex-direction: column;
    button {
      background-color: transparent;
      border-radius: 5px;
      cursor: pointer;
      margin: 5px 10px;
      height: 40px;
      border: 0;
      &.active {
        background-color: #d6d6d6;
        &:hover {
          background-color: #cecece;
        }
      }
    }
    border-bottom: 1px solid #d6d6d6;
  }
  .show-filter-tags {
    //margin-bottom: 10px;
    //height: 100px;
    min-height: 40px;
    .filterTags {
      display: flex;
      cursor: pointer;
      user-select: none;
      padding-top: 10px;
      margin: 0 10px;
      color: #666;
      font-size: 12px;

      .tag {
        flex: 1;
      }
      .clear {
        //width: 100%;
        //flex: ;
        button {
          background-color: transparent;
          border: 0;
          color: #666;
          cursor: pointer;
        }
      }
    }
  }

  .all-tags {
    //border-top: 1px solid #d6d6d6;
    user-select: none;
    //margin: 0 2px;
    height: 250px;
    //max-height: 500px;
    overflow-x: hidden;
    //margin-top: 100px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    //opacity: 0.2;

    &::-webkit-scrollbar-thumb {
      background-color: transparent;

    }
    &:hover {
      //opacity: 0.9;
      .tag {
        opacity: 0.7;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    .tag {
      margin-top: 5px;
      flex-shrink: 0; // 禁止折叠
      //display: inline-block;
      height: 20px;
      font-size: 10px;
      padding: 2px 8px;
      border-radius: 10px;
      margin-right: 2px;
      cursor: pointer;
      display: flex;
      transition: opacity 0.3s ease-in-out;
      opacity: 0.1;
      &.active {
        opacity: 1;
        padding: 0 6px;
        border: 2px solid #f5b615;
      }
      .tag-name {
        display: block;
        max-width: 90px;
        white-space: nowrap;
        overflow: hidden;
        //margin-right: 5px;
      }
      .number {
        display: block;
        //align-items: center;
        //justify-content: center;
        //background-color: red;
        padding-left: 5px;
        margin-left: 5px;
        border-left: 1px white solid;
        //border: 1px red solid;
        //margin-right: 7px;
        //width: 15px;
      }
    }

  }

  .footer {
    @media screen and (max-width: 499px) {
      font-size: 30px;
    }
    //flex: 1;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .setting {
      color: #d5d5d5;
      display: flex;
      align-items: center;
      .logo {
        margin-right: 5px;
      }
      .setting-item {
        cursor: pointer;
        margin-right: 5px;
        .toggle {

        }
      }
    }
    .secret {
      color: #d5d5d5;
      .secret-toggle {
        cursor: pointer;
      }
    }
    .text {
      font-size: 13px;
      color: #d5d5d5;
    }

    .pull-log {
      cursor: pointer;
      margin-left: 5px;
    }
  }
}
</style>
