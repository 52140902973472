<template>
  <div class="setting-dialog" ref="settingRef">
    <div class="container">

            <h2>同步设置</h2>
            <div class="setting-item">
              <label for="token">token:</label> <input id="token" type="text" v-model="noteToken">
            </div>

<!--      <h2>同步设置</h2>-->
<!--      <div class="setting-item">-->
<!--        开启同步 <input type="checkbox" v-model="updateLogsOpen">-->
<!--      </div>-->
<!--      <div class="setting-item">-->
<!--        <label for="serverUrl">服务器网址:</label> <input id="serverUrl" type="text" v-model="serverUrl">-->
<!--      </div>-->
<!--      <div class="setting-item">-->
<!--        <label for="serverPassword">服务器密码:</label> <input id="serverPassword" type="password" v-model="serverPassword">-->
<!--      </div>-->
<!--      <div class="setting-item">-->
<!--        <label for="updateTime">最后一次同步时间:</label> <input id="updateTime" type="number" v-model="updateCreateAt"> {{ new Date(updateCreateAt).toLocaleString()}}-->
<!--      </div>-->
<!--      <div class="setting-item">-->
<!--        <label for="updatePullTime" title="只要进行点击操作就会进行拉取更新">拉取更新延迟(秒):</label> <input id="updatePullTime" type="number" v-model="updatePullTime">-->
<!--      </div>-->
<!--      <div class="setting-item">-->
<!--        删除本地同步进度，重新进行同步 <button @click="deleteUpdateLogsIdList">删除</button>-->
<!--      </div>-->
      <h2>代码浮动</h2>
      <div class="setting-item">
        代码是否浮动 <input type="checkbox" v-model="$store.state.setting.codeFlow">
      </div>

      <h2>点击卡片动作</h2>
      <div class="setting-item">
        左键为编辑 <input type="checkbox" v-model="$store.state.setting.leftEdit">
      </div>

      <h2>智能标签</h2>
      <div>
        <input type="text" placeholder="正则表达式" v-model="smartTagItem.regex">
        <input type="text" placeholder="标签文本" v-model="smartTagItem.text">
        <button @click="addSmartTag()">添加</button>
      </div>
      <div class="setting-item" v-for="smartTag in $store.state.setting.smartTags" :key="smartTag.text">
        <input type="text" v-model="smartTag.regex">
        <input type="text" v-model="smartTag.text">
        <input class="colorSelect bg" title="背景色" type="color" v-model="smartTag.background"/>
        <input class="colorSelect text" title="文字颜色" type="color" v-model="smartTag.color"/>
        <button @click="delSmartTag(smartTag)">删除</button>
      </div>
      <div class="setting-item">
        智能标签完整的配置
      </div>
      <textarea v-model="smartTagsStr"></textarea>
    </div>
  </div>
</template>

<script>
import saveLoadMixin from '@/mixin/saveLoadMixin'
import axios from "axios"
import typechoMixin from "@/mixin/typechoMixin"

export default {
  name: 'SettingDialog',
  mixins: [saveLoadMixin, typechoMixin],
  data() {
    return {
      smartTagItem: {
        regex: "", // /www.npmjs.com/i,
        text: '',
        background: '#5c6bc0',
        color: '#ffffff',
      },
    }
  },
  computed: {
    // 用computed来双向绑定vuex中的数据
    serverUrl: {
      get() {
        return this.$store.state.setting.serverUrl
      },
      set(value) {
        this.$store.state.setting.serverUrl = value
      }
    },
    noteToken: {
      // 双向绑定来获取localStorage中的数据
      get() {
        return "内容加密"
        // return localStorage.getItem('note_token') || ''
      },
      set(value) {
        if(value === "内容加密") return
        const hash_token = this.hashData(value)
        localStorage.setItem('note_token', hash_token)
        this.syncTime = 0
        axios.defaults.headers.common.Authorization = hash_token
      }
    },
    updatePullTime: {
      get() {
        return this.$store.state.setting.updatePullTime / 1000
      },
      set(value) {
        this.$store.state.setting.updatePullTime = value * 1000
      }
    },
    updateCreateAt: {
      get() {
        return this.$store.state.setting.updateCreateAt
      },
      set(value) {
        this.$store.state.setting.updateCreateAt = value
      }
    },
    serverPassword: {
      get() {
        return this.$store.state.setting.serverPassword
      },
      set(value) {
        this.$store.state.setting.serverPassword = value
      }
    },
    updateLogsOpen: {
      get() {
        return this.$store.state.setting.updateLogsOpen
      },
      set(value) {
        this.$store.state.setting.updateLogsOpen = value
      }
    },
    smartTagsStr: {
      get() {
        return JSON.stringify(this.$store.state.setting.smartTags)
      },
      set(value) {
        this.$store.state.setting.smartTags = JSON.parse(value)
      }
    }
  },
  methods: {
    hashData(data) {
      const CryptoJS = require("crypto-js")

      // CryptoJS 直接对字符串进行操作，无需转换为字节
      const hash = CryptoJS.SHA256(data)

      // 将生成的哈希值转换为十六进制字符串
      const hashHex = hash.toString(CryptoJS.enc.Hex)

      return hashHex
    },
    deleteUpdateLogsIdList() {
      this.$store.state.updateLogsIdList = []
      localStorage.removeItem('ideaNotes-updateLogsIdList')
    },
    addSmartTag() {
      this.$store.state.setting.smartTags.push(this.smartTagItem)
    },
    delSmartTag(smartTag) {
      const msg = "您真的确定要删除吗？\n\n请确认！"
      if (!confirm(msg)) {
        return true
      }
      this.$store.state.setting.smartTags = this.$store.state.setting.smartTags.filter(item => {
        return !(item.regex === smartTag.regex && item.text === smartTag.text)
      })
    }
  },
  mounted () {
    this.loadSetting()
    // 绑定编辑框背景点击事件，目前只能这样，因为会事件冒泡，还解决不了
    this.$refs.settingRef.addEventListener('mousedown', (event) => {
      if (event.target === this.$refs.settingRef) { // 判断是否是当前绑定事件的元素元素触发的该事件
        // this.editClose()
        this.$parent.settingShow = false
        this.saveSetting()
        event.cancelBubble = true
        this.loadSetting()
      }
    })
  }
}
</script>

<style scoped lang="scss">
.setting-dialog {
  position: fixed; // 全屏显示编辑框
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //background-color: rgba(58, 56, 56, 0.72);
  background-color: #f4f4f4;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  padding-left: 0;
  padding-right: 0;
  @media screen and (max-width: 700px) {
    padding-top: 10px;
    padding-left: 10px;
  }
  .container {
    @media screen and (max-width: 700px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
    margin-bottom: 20px;
    margin-left: 10px;
    width: 700px; // 指定了一个宽度，如果不指定就会很贴合的
    border-radius: 10px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
</style>
