import packageInfo from '../../package.json'
import Toastify from "toastify-js"
/**
 * 公共的小工具
 * @module utils/utils
 * @requires crypto-js 用来加密
 */
const CryptoJS = require("crypto-js")
/**
 * 防抖函数
 * @param fn 传入的函数
 * @param wait 等待的时间，默认600毫秒
 */
export function debounce(fn, wait) {
  wait = wait || 600

  if (window['' + fn]) clearTimeout(window['' + fn])
  window['' + fn] = setTimeout(() => {
    fn()
  }, wait)
}

export function showToastSuccess(message) {
  const toastElement = Toastify({
    text: message,
    gravity: 'bottom',
    position: 'right',
    duration: 10000,
    close: false,
    backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)", // 绿色调，表示成功
  })
  toastElement.showToast()
}

export function showToastError(message) {
  const toastElement = Toastify({
    text: message,
    gravity: 'bottom',
    position: 'right',
    duration: 10000,
    close: false,
    backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)", // 红色调，表示失败
  })
  toastElement.showToast()
}

/**
 * 节流函数
 */
export function throttle(fn, wait) {
  wait = wait || 600

  if (window['' + fn]) return
  fn()
  window['' + fn] = setTimeout(() => {
    fn()
    window['' + fn] = null
  }, wait)
}

/**
 * 控制台输出版本信息
 * @example
 * idea-note
 * 版 本 号：0.8.0
 * 编译日期：2022-11-23 01:51:34
 */
export function copyRightConsole() {
  /* 样式代码 */
  const projectNameStyle = 'font-size: 20px;font-weight: 600;color: rgb(244,167,89);'
  const descriptionStyle = 'font-style: oblique;font-size:14px;color: rgb(244,167,89);font-weight: 400;'
  const versionStyle = 'color: rgb(30,152,255);padding:8px 0 2px;'
  const dateTimeStyle = 'color: rgb(30,152,255);padding:0 0 5px;'

  /* 内容代码 */
  const projectName = packageInfo.name || ''
  const description = packageInfo.description || ''
  const version = `版 本 号：${packageInfo.version}` //     【ArcGIS API for JavaScript 版本：${packageInfo?.dependencies?.['@c_arcgis/core'] || packageInfo?.dependencies?.['@arcgis/core']}】
  const dateTime = `编译日期：${timeFormat(window.versionTime)}`

  // 空格有意义，不要格式化
  console.log(`%c${description} %c${projectName}
%c${version}
%c${dateTime}`, projectNameStyle, descriptionStyle, versionStyle, dateTimeStyle)
}

/**
 * 时间格式化函数
 * @param time
 * @returns {string}
 */
export function timeFormat(time) {
  const dat = new Date(time)
  // 获取年月日，时间
  const year = dat.getFullYear()
  const mon = (dat.getMonth() + 1) < 10 ? "0" + (dat.getMonth() + 1) : dat.getMonth() + 1
  const data = dat.getDate() < 10 ? "0" + (dat.getDate()) : dat.getDate()
  const hour = dat.getHours() < 10 ? "0" + (dat.getHours()) : dat.getHours()
  const min = dat.getMinutes() < 10 ? "0" + (dat.getMinutes()) : dat.getMinutes()
  const seon = dat.getSeconds() < 10 ? "0" + (dat.getSeconds()) : dat.getSeconds()

  const newDate = year + "-" + mon + "-" + data + " " + hour + ":" + min + ":" + seon
  return newDate
}

/**
 * @summary 拷贝内容到剪切板
 * @param text {String} 要拷贝的内容
 */
export async function copyToClipbrd(text) {
  try {
    await navigator.clipboard.writeText(text)
    console.log('Text successfully copied to clipboard')
  } catch (err) {
    console.error('Unable to copy text to clipboard', err)
  }
}

/**
 * 密钥是 www.glwsq.cn
 * @summary 对文本进行对称加密
 * @tutorial 笔记的加密与解密
 * @param data {String} 要加密的字符串
 * @returns {String} 加密后的字符串
 */
export function encrypt(data) {
  const ciphertext = CryptoJS.AES.encrypt(data, 'www.glwsq.cn').toString()
  return ciphertext
}

/**
 * 密钥是 www.glwsq.cn
 * @summary 对加密后的文本进行解密
 * @tutorial 笔记的加密与解密
 * @param data {String} 要解密的字符串
 * @returns {string} 解密后的字符串
 */
export function decrypt(data) {
  const bytes = CryptoJS.AES.decrypt(data, 'www.glwsq.cn')
  const originalText = bytes.toString(CryptoJS.enc.Utf8)
  return originalText
}

/**
 * 可以指定种子的随机函数发生器
 * @summary 随机数发生器
 * @constructor
 */
export class SeedRand {
  /**
   * 构造函数
   * @param options {{seed: Number, be: Number}} `seed 种子，be 保留位数`
   */
  constructor(options) {
    const _options = {
      seed: Date.now(), // 随机种子
      be: 0 // 保留小数点
    }
    for (const b in options) _options[b] = options[b]
    this.seed = (_options.seed || Date.now()) % 999999999
    this.be = _options.be
  }

  /**
   * 取一个随机整数
   * @param max {number} 最大值（0开始，不超过该值） 默认10
   * @returns {number} 返回一个随机数
   */
  next(max) {
    max = max || 10
    this.seed = (this.seed * 9301 + 49297) % 233280
    const val = this.seed / 233280.0
    return Number((val * max).toFixed(this.be))
  }
}

export function encryptExport(data) {

}

export function decryptExport(data) {

}
