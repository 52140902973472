import { nanoid } from "nanoid"
import { showToastError, showToastSuccess } from '@/utils/utils'
import axios from "axios"

/**
 * 这个混入文件用来进行数据存入localStorage中和从localStorage中拿数据
 * @summary 保存和载入混入
 * @author happen
 * @tutorial 存入localStorage注意事项
 * @module mixin/saveLoadMixin
 */
export default {
  computed: {
    syncTime: {
      // 双向绑定来获取localStorage中的数据
      get() {
        return parseInt(localStorage.getItem('syncTime') || 0)
      },
      set(value) {
        localStorage.setItem('syncTime', value)
      }
    },
  },
  methods: {
    nowTime() {
      return Math.floor(new Date().getTime() / 1000)
    },
    updateNoteTypecho(note_id, note_data) {
      const axios = require('axios')

      const post_data = {
        text: note_data.content,
        title: note_data.title,
        status: note_data.postStatus,
        password: note_data.password,
        modified: note_data.updateAt
      }

      if(note_data.tags) {
        // 将tags对象转换为数组，排除掉smart为true的标签
        post_data.tagNames = Object.entries(note_data.tags)
          .filter(([key, value]) => !value.smart)
          .map(([key, value]) => {
            return key
          })
        console.log("post_data.tags", post_data.tags)
      }

      post_data.created = this.convertTimestamp(note_data.created)
      post_data.modified = this.convertTimestamp(note_data.modified)

      const data = JSON.stringify(post_data)

      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: '/typecho-api/posts/by-note-id/' + note_id,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }

      axios.request(config)
        .then((response) => {
          const filteredArray = this.$store.state.contentArr.filter(item => item.id === note_id)
          filteredArray[0].syncTime = Math.floor(new Date().getTime() / 1000)

          localStorage.setItem('ideaNotes', JSON.stringify(this.$store.state.contentArr))

        })
        .catch((error) => {
          console.log(error)
          const filteredArray = this.$store.state.contentArr.filter(item => item.id === note_id)
          filteredArray[0].syncTime = 0

          localStorage.setItem('ideaNotes', JSON.stringify(this.$store.state.contentArr))
          showToastError("更新上传失败")
        })

    },
    // 处理本地存在但网络上不存在的笔记
    async uploadLocalNotesSequentially(notes, apiNoteIds, index = 0) {
      if (index >= notes.length) return // 递归终止条件

      const localNote = notes[index]

      if (!apiNoteIds.has(localNote.id) && !localNote.syncTime) { // 本地笔记不在线上笔记里面，并且syncTime为空或者0
        console.log(apiNoteIds.has(localNote.id), localNote.syncTime)

        // 调用函数上传笔记
        await this.addNoteTypecho(localNote.id, localNote)
        // showToastSuccess("本地笔记上传成功:" + localNote.content.slice(0, 10))
      }
      // setTimeout(async () => {
      // 递归调用函数处理下一条笔记
      await this.uploadLocalNotesSequentially(notes, apiNoteIds, index + 1)
      // }, 500)
    },
    // 本地和互联网同步
    updateRemoteNotes() {
      console.log("将本地笔记上传到远程")
      this.$store.state.contentArr.filter(item => !item.syncTime)
        .forEach(localNote => {
          this.addNoteTypecho(localNote.id, localNote)
          this.updateNoteTypecho(localNote.id, localNote)
          console.log(localNote)
        })
    },
    // 互联网和本地同步
    updateLocalNotes(apiNotes) {
      // 将远程同步到本地
      // 遍历获取到的所有数据
      apiNotes.forEach(apiNote => {
        // 找到本地笔记
        const localNote = this.$store.state.contentArr.find(note => note.id === apiNote.fields.note_id)
        // 如果网络的笔记没有note_id 添加一个
        if(!apiNote.fields.note_id) {
          apiNote.fields.note_id = nanoid()
          this.updateNoteTypecho(apiNote.fields.note_id)
        }
        // 如果没有内容，也添加一下
        if(!apiNote.text) {
          apiNote.text = "无内容"
        }
        // 将网络的笔记的tag转换为本地的格式
        const tags = {}
        // 重建 note_data 结构中的 tags 部分
        if(apiNote.tags) {
          // console.log("apiNote.tags", apiNote.tags)
          for(const tag of apiNote.tags) {
            tags[tag.name] = {
              background: "#5c6bc0", // 默认背景色
              color: "#ffffff", // 默认字体色
              smart: false // 默认smart值
            }
          }
          // console.log("tags", tags)
        }
        // 本地笔记存在的话
        if (localNote) {
          // console.log(apiNote.fields.note_id)
          // 如果本地存在并且网络上的修改时间晚于本地，则更新本地笔记
          if (apiNote.modified >= localNote.updateAt) {
            // 笔记格式转换
            localNote.content = apiNote.text
            localNote.title = apiNote.title
            localNote.updateAt = apiNote.modified
            localNote.password = apiNote.password
            localNote.full_url = apiNote.full_url
            localNote.tags = tags
            localNote.postStatus = apiNote.status
            localNote.syncTime = Math.floor(new Date().getTime() / 1000)
            // 这里还可以添加更多的字段更新，如 background, color 等
          } else if (apiNote.modified < localNote.updateAt) {
            // 更新网络上的
            this.addNoteTypecho(localNote.id, localNote)

          }
        } else {
          // console.log(apiNote.fields.note_id)
          // 如果本地不存在此笔记，则添加新笔记
          // 笔记格式转换
          this.$store.state.contentArr.push({
            content: apiNote.text,
            title: apiNote.title,
            background: "#ffffff", // 假设背景颜色
            postStatus: apiNote.status, // publish private
            color: "#333333", // 假设文本颜色
            top: 0, // 假设的其他属性
            tags: tags, // 假设的标签
            state: 0, // 假设的状态
            secret: false, // 假设的隐私状态
            type: "", // 假设的类型
            height: 96, // 假设的高度
            syncTime: Math.floor(new Date().getTime() / 1000),
            password: "",
            id: apiNote.fields.note_id,
            createAt: apiNote.created,
            updateAt: apiNote.modified
          })
        }
      })

      // 将本地同步到远程
      // 收集所有网络笔记的 ID
      // const apiNoteIds = new Set(apiNotes.map(apiNote => apiNote.fields.note_id))
      // 使用此函数开始上传过程
      // this.uploadLocalNotesSequentially(this.$store.state.contentArr, apiNoteIds)

    },
    // 通过网络更新本地的笔记列表
    getNoteListTypecho() {
      const axios = require('axios')

      const per_page = 10000
      // if(this.syncTime === 0) {
      //   per_page = 10000
      // }

      // Recursive function to fetch data in batches
      const fetchDataInBatches = (page = 1, perPage = per_page) => {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `/typecho-api/posts?page=${page}&per_page=${perPage}&modified_threshold=${this.syncTime}`,
          headers: {}
        }

        axios.request(config)
          .then((response) => {
            if(response.status === 200) {
              console.log(response.data.posts)
              this.updateLocalNotes(response.data.posts)
              this.syncTime = this.nowTime()
              localStorage.setItem('ideaNotes', JSON.stringify(this.$store.state.contentArr))

              // 如果还有页码，进行下一次递归
              if (page < response.data.total_pages) {
                setTimeout(() => {
                  fetchDataInBatches(page + 1, perPage) // Fetch the next batch
                }, 10000)
              }
            } else {
              showToastError('获取在线笔记失败')
            }

          })
          .catch((error) => {
            console.log(error)
            if(error.response.status === 401) {
              showToastError('token验证失败，请重新设置')
            }
          })
      }
      // Start fetching from the first page
      fetchDataInBatches()
    },
    convertTimestamp(timestamp) {

      // Check if timestamp is undefined or not a number
      if (timestamp === undefined || typeof timestamp !== 'number') {
        return Math.floor(new Date().getTime() / 1000)
      }

      // Convert the timestamp to a string and check its length
      const timestampStr = timestamp.toString()

      // If the timestamp is 13 digits long, convert it to 10 digits
      if (timestampStr.length === 13) {
        return Math.floor(timestamp / 1000)
      } else {
        // Return the original timestamp if it's not 13 digits
        return timestamp
      }
    },
    addNoteTypecho(note_id, note_data) {
      // 突然发现添加笔记不需要单独的note_id，note_data中可以包含
      const axios = require('axios')

      const post_data = {
        "text": note_data.content,
        "title": note_data.title,
        "note_id": note_data.id,
        "categoryName": "快速笔记",
        "created": note_data.createAt,
        "tagNames": [],
        "modified": note_data.updateAt,
      }

      if (note_data.tags) {
        // 将tags对象转换为数组，排除掉smart为true的标签
        post_data.tagNames = Object.entries(note_data.tags)
          .filter(([key, value]) => !value.smart)
          .map(([key, value]) => key)
      }

      post_data.created = this.convertTimestamp(post_data.created)
      post_data.modified = this.convertTimestamp(post_data.modified)

      const data = JSON.stringify(post_data)

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/typecho-api/posts',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data))
          // 上传成功
          // 从this.$store.state.contentArr找到id为note_id的
          const filteredArray = this.$store.state.contentArr.filter(item => item.id === note_id)
          filteredArray[0].syncTime = Math.floor(new Date().getTime() / 1000)

          localStorage.setItem('ideaNotes', JSON.stringify(this.$store.state.contentArr))
        })
        .catch((error) => {
          console.log('添加上传失败', error.response.status, note_data)
          if (error.response.status === 400) {
            const filteredArray = this.$store.state.contentArr.filter(item => item.id === note_id)
            for(const a of filteredArray) {
              a.syncTime = Math.floor(new Date().getTime() / 1000)
            }
            console.log(filteredArray)
            localStorage.setItem('ideaNotes', JSON.stringify(this.$store.state.contentArr))
          } else {
            showToastError('添加上传失败')
          }
        })

    },
    uploadImage(file, note_id, newFileName) {
      const axios = require('axios');
      const formData = new FormData();
      formData.append("image", file);
      formData.append("note_id", note_id); // 添加文章ID
      formData.append("newFileName", newFileName); // 添加新文件名

      const config = {
        method: 'post',
        url: '/typecho-api/upload',
        headers: {
          // 添加任何必要的头信息，例如认证令牌
          // 'Authorization': 'Bearer ' + yourAuthToken,
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      };

      axios.request(config)
        .then(response => {
          if (response.data.error) {
            showToastError('上传失败: ' + response.data.error);
          } else {
            // 处理成功的响应，例如更新UI或调用其他函数
            showToastSuccess('上传成功');
            // 返回数据以便进一步处理
            return response.data;
          }
        })
        .catch(error => {
          console.error('Upload Error:', error);
          showToastError('上传失败');
        });
    },
    deleteNoteTypecho(note_id) {
      const axios = require('axios')

      const config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: '/typecho-api/posts/by-note-id/' + note_id,
        headers: { }
      }

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data))
          if ('error' in response.data) {
            showToastError('删除失败')
          } else {
            showToastSuccess('删除成功')
          }

        })
        .catch((error) => {
          console.log(error)
          showToastError('删除失败')
        })

    }
  }
}
