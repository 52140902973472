import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'font-awesome/css/font-awesome.min.css'
import "toastify-js/src/toastify.css"
import 'highlight.js/styles/github.css'
import localforage from "localforage"
import { decrypt } from "@/utils/utils"
import axios from "axios"

Vue.config.productionTip = false

// 从localstorage中获取token
const apiToken = localStorage.getItem('note_token') || ''

// 设置全局默认配置
axios.defaults.headers.common.Authorization = apiToken

/**
 * 将老的笔记数据转换到localforge中
 * 后续版本可以删除这个操作，本次操作添加时间 2023-09-09 20:39:53
 * @returns {Promise<void>}
 */
// async function data_to_localforge() {
//   try {
//     const value = await localforage.getItem('NoteLogs')
//     // This code runs once the value has been loaded
//     // from the offline store.
//     console.log("db中拿到的数据", value)
//     if(!value) {
//       if(localStorage.getItem('NoteLogs')) {
//         const note_logs = JSON.parse(decrypt(localStorage.getItem('NoteLogs'))) || []
//         localforage.setItem('NoteLogs', note_logs).then(function (value) {
//           // 当值被存储后，可执行其他操作
//           console.log("localforage初始化保存成功")
//         }).catch(function(err) {
//           // 当出错时，此处代码运行
//           console.log(err)
//         })
//       }
//     }
//   } catch (err) {
//     // This code runs if there were any errors.
//     console.log("db中拿到的数据", err)
//   }
// }
//
// data_to_localforge()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
