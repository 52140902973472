/**
 * 这个混入文件用来进行数据存入localStorage中和从localStorage中拿数据
 * @summary 保存和载入混入
 * @author happen
 * @tutorial 存入localStorage注意事项
 * @module mixin/saveLoadMixin
 */
export default {
  methods: {
    /**
     * 本方法会将笔记系统的数据存入本地的localStorage中
     * @summary 将数据存入本地
     * @author happen
     * @function saveSetting
     */
    saveSetting() {
      console.log("保存设置了")
      localStorage.setItem('ideaNotes-setting', JSON.stringify(this.$store.state.setting))
      // localStorage.setItem('ideaNotes-updateLogsIdList', JSON.stringify(this.$store.state.updateLogsIdList))
    },
    /**
     * @summary 载入设置
     * @author happen
     * @function loadSetting
     */
    loadSetting() {
      const setting = JSON.parse(localStorage.getItem('ideaNotes-setting') || '{}')
      this.$store.state.setting = { ...this.$store.state.setting, ...setting }
      // this.$store.state.updateLogsIdList = JSON.parse(localStorage.getItem('ideaNotes-updateLogsIdList') || '[]')
    },
  }
}
