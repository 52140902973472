import Vue from 'vue'
import Vuex from 'vuex'
import { getToken } from '@/utils/auth'

Vue.use(Vuex)
/**
 * vuex 作为一个全局变量的场景使用
 * @module store-vuex
 */
export default new Vuex.Store({
  state: {
    /** @member {Number} logNum 日志数量 */
    logNum: 0, // 日志数量
    /** @member {Array} contentArr 原始文章内容列表 */
    contentArr: [], // 原始文章内容列表
    setting: {
      serverUrl: 'http://localhost:3000', // 服务器同步url
      ServerPassword: '', // 同步密码
      updateCreateAt: 0, // 当前获取的日志时间
      updatePullTime: 1000 * 10, // 拉取更新延迟时间
      updateLogsOpen: false, // 是否开启同步
      deleteATagHref: true, // 删除卡片中a标签的点击跳转
      clearFilterRulesIncludeTags: true, // 是否开启点击空白清空[包含]标签
      clearFilterRulesTags: true, // 是否开启点击空白清空[只包含]标签
      codeFlow: false, // 代码浮动
      leftEdit: true,
      smartTags: [ // 智能标签
        {
          regex: "<a ", // /<a /i
          text: '网址',
          background: '#56b9ba',
          color: '#fff',
        },
        {
          regex: "<code class=\"hljs language", // /<code class="hljs language/i,
          text: '代码',
          background: '#92cc77',
          color: '#fff',
        },
        {
          regex: "<img ", // /<img /i,
          text: '图片',
          background: '#fdf8b3',
          color: '#484848',
        },
        {
          regex: "www.bilibili.com", // /www.bilibili.com/i,
          text: '哔哩哔哩',
          background: '#fb7299',
          color: '#ffffff',
        },
        {
          regex: "www.npmjs.com", // /www.npmjs.com/i,
          text: 'npm',
          background: '#cb3837',
          color: '#ffffff',
        },
        {
          regex: "github.com", // /github.com/i,
          text: 'GitHub',
          background: '#49535e',
          color: '#ffffff',
        },

      ],
    },
    pullNoteLogFlag: false, // 拉取更新状态
    noteLogs: [], // 保存所有的日志信息
    updateLogsIndex: 0, // 同步日志编号
    updateFlag: false, // 同步状态
    updateInterval: null, // 同步循环
    pullInterval: null, // 拉取循环
    updateLogsIdList: [], // 保存已经上传的日志列表id
    updateLogState: 1, // 同步状态 0 正在同步 1 同步完成  2 同步失败
    user: {
      token: getToken(),
      username: ''
    }
  },
  getters: {

  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
