<template>
  <div class="edit-container" ref="edit-container"  @click.stop="editContainerClick">
    <div id="setting">
      <div class="setting-list">
        <select v-model="editItem.postStatus">
          <option value="publish">公开</option>
          <option value="hidden">隐藏</option>
<!--          <option value="password">密码保护</option>-->
          <option value="private">私密</option>
<!--          <option value="waiting">待审核</option>-->
        </select> <br>
        <input v-model="editItem.password" style="width: 80px" type="text" placeholder="请输入密码">
      </div>
    </div>
    <div id="edit-main" :class="{expend: expand}">
      <div class="header">
        <span class="status">{{editItem.id ? '编辑': '创建'}}</span>
        <span class="tools">
            <button @click="editInsertCode" title="插入代码"><i class="fa fa-code"></i></button>
            <button @click="editInsertHeader" title="插入标题"><i class="fa fa-header"></i></button>
          <button @click="editInsertMore" title="插入折叠内容">more</button>
          </span>
        <span v-if="editItem.id" class="timeBar">
            <span class="time">创建时间: {{ timeFormat(editItem.createAt) }}</span>
            <span class="time">更新时间: {{ timeFormat(editItem.updateAt) }}</span>
          </span>
        <span class="expand"><button @click="editExpand"><i class="fa" :class="!expand ? 'fa-expand': 'fa-compress'"></i></button></span>
        <span class="close"><button @click="editClose">完成</button></span> <!---->
      </div>
      <div id="edit-area">
        <MarkdownEditer ref="editorRef" v-model="editItem.content" @input="input" :note-id="editItem.id"></MarkdownEditer>
      </div>
      <div class="title">
        <input class="title-text" type="text" v-model="editItem.title">
      </div>

      <vue-tags-input
        v-model="editTag.tag"
        :tags="editTag.tags"
        :autocomplete-items="filteredItems"
        placeholder="添加标签"
        @tags-changed="newTags => editTag.tags = newTags"
      />
      <!-- 卡片修改背景色和字体字体颜色感觉效果很不好，所以取消 -->
<!--      <div class="colorTools" v-if="false">-->
<!--        <input class="colorSelect bg" title="背景色" type="color" v-model="editItem.background"/>-->
<!--        <input class="colorSelect text" title="文字颜色" type="color" v-model="editItem.color"/>-->
<!--        <div class="colorList">-->
<!--          <span class="colorItem" :style="{ background: item.background, color: item.color }" v-for="(item, index) of colors" :key="index">{{item.text}}</span>-->
<!--        </div>-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>

import VueTagsInput from '@johmun/vue-tags-input'
import MarkdownEditer from "@/components/MarkdownEditer/CodeMirror.vue"
import { debounce, timeFormat } from '@/utils/utils'

export default {
  name: 'EditDialog',
  components: { VueTagsInput, MarkdownEditer },
  props: {
    editItem: {
      type: Object
    },
    editTag: {
      type: Object
    },
  },
  data() {
    return {
      expand: false
    }
  },
  computed: {
    filteredItems() {
      return this.editTag.autocomplete.filter(i => {
        return i.text.toLowerCase().indexOf(this.editTag.tag.toLowerCase()) !== -1
      })
    },
  },
  methods: {

    /**
     * 编辑框放大缩小
     */
    editExpand() {
      this.expand = !this.expand
    },
    editInsertMore() {
      this.$refs.editorRef.insertAtCurrentPosition('\n\n<!--more-->\n\n', 5)
    },
    editInsertCode() {
      this.$refs.editorRef.insertAtCurrentPosition('\n```\n\n```\n', 3)
    },
    editInsertHeader() {
      this.$refs.editorRef.insertAtCurrentPosition('## ', 3)
    },
    timeFormat(time) {
      return timeFormat(time)
    },
    input(value) {
      // 自动保存笔记
      debounce(() => {
        console.log("自动保存")
        this.$parent.saveNote()
      }, 10000)
    },
    /**
     * 刷新输入框的高度
     */
    changeEditHeight() {
      // 获取屏幕高度
      // const cHeight = document.documentElement.clientHeight || document.body.clientHeight
      // const textarea = this.$refs.editRef
      //
      // textarea.style.height = '200px'
      // const nowHeight = textarea.scrollHeight
      // console.log("textarea.scrollHeight", textarea.scrollHeight)
      // if(nowHeight <= cHeight - 200) {
      //   textarea.style.height = nowHeight + 'px'
      //   // textarea.style.overflowY = 'hidden'
      // } else if(nowHeight > cHeight - 200) {
      //   textarea.style.height = (cHeight - 200) + 'px'
      // }
      // textarea.style.scrollTop = 0 // 防抖动

      // console.log(textarea.scrollHeight)

    },
    editClose() {
      this.$emit('editClose')
    },
    editContainerClick() {
      // 为了解决编辑框背景点击事件冒泡的问题
    },
  },
  created () {
    // 判断屏幕宽度，如果太窄了，自动把菜单收起来
    const clientW = document.documentElement.clientWidth || document.body.clientWidth
    if(clientW < 700) {
      this.expand = true
    }
  },
  mounted () {
    // this.$nextTick(() => {
    //   this.$refs.editRef.focus()
    // })

    // 绑定编辑框背景点击事件，目前只能这样，因为会事件冒泡，还解决不了
    this.$refs['edit-container'].addEventListener('mousedown', (event) => {
      if (event.target == this.$refs['edit-container']) { // 判断是否是当前绑定事件的元素元素触发的该事件
        // this.editClose()
        this.$emit('editClose')
        event.cancelBubble = true
      }
    })

    // js 计算content 这个变量字符串内容的行数，然后根据行数来设置一个div id=edit-area的高度，如果内容超过20行，那么设置最多显示20行
    // 计算行数
    const lineCount = this.editItem.content.split("\n").length

    // 获取 div 元素
    const editArea = document.getElementById('edit-area')
    const editMain = document.getElementById('edit-main')

    // 获取屏幕高度
    const clientH = document.documentElement.clientHeight || document.body.clientHeight
    const clientW = document.documentElement.clientWidth || document.body.clientWidth

    // 如果行数超过20，则设置高度以显示最多20行
    // 假设每行的高度是20px，这个值可以根据实际情况调整
    if (lineCount > 20) {
      editArea.style.height = clientH - 200 + 'px' // 20行 * 20px
      editMain.style.width = '800px'
    } else if (lineCount < 10) {
      editArea.style.height = '200px' // 20行 * 20px
      editMain.style.width = '500px'
    } else {
      // 否则，根据实际行数设置高度
      editArea.style.height = (lineCount * 14 > 200 ? lineCount * 14 : 200) + 'px'
      editMain.style.width = (500 + lineCount * 9) + 'px'
    }

  }
}
</script>

<style lang="scss" scoped>
.edit-container {
  position: fixed; // 全屏显示编辑框
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(58, 56, 56, 0.72);
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  #setting {
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    .setting-list {
      //display: flex;
      //flex-direction: column;

      //justify-content: center;
      //align-items: center;
      //.setting-item {
      //  margin-bottom: 10px;
      //  cursor: pointer;
      //  &.active {
      //    color: #409EFF;
      //  }
      //}
    }
    //width: 100px;
    //height: 100px;
    //background-color: white;
  }
  #edit-main {
    transition: all 0.1s linear;
    &.expend {
      width: 100% !important;
      height: 100%;
      border-radius: 0;
      #edit-area {
        flex: 1;
      }
      //textarea {
      //  //height: 100%;
      //  flex: 1;
      //}
      .vue-tags-input {
        //height: 70px;
        margin-bottom: 30px;
      }
    }
    //position: absolute;

    .title {
      //text-align: right; /* 使子元素（输入框）靠右对齐 */
      z-index: 10;

      position: absolute; /* 使用固定定位 */
      left: 0;               /* 设置左偏移为父元素宽度的 50% */
      //transform: translateX(-50%); /* 向左移动自身宽度的 50% */
      bottom: -30px;       /* 靠底部 */
      //padding-left: -20px;   /* 可选：添加一些内边距 */
      width: 100%;
      border-radius: 10px;
    }

    .title-text {
      border-radius: 5px;
      text-align: center;
      width: 100%;
      //margin-left: auto;
      //margin-right: auto;
      border: none;      /* 移除边框 */
      outline: none;     /* 点击时不显示轮廓 */
    }

    width: 500px;
    border-radius: 10px;
    box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.29);

    //height: 200px;
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    .header {
      height: 30px;
      position: absolute;
      left: 0;
      border-bottom: 1.5px solid rgba(178, 178, 178, 0.7);
      width: calc(100%);
      display: flex;
      user-select: none;
      color: #888;
      .status {
        font-weight: bold;
        //font-family: "黑体";
        margin-left: 10px;

        transform: translateY(-2px);
      }
      .tools {
        margin-left: 10px;
        button {
          background-color: transparent;
          //border: 1px red solid;
          border: 0;
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 5px;
          transform: translateY(-4px);
          &:hover {
            background-color: #dcdcdc;
          }
        }
      }
      .timeBar {
        //display: inline-block;
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: translateY(-4px);
        //flex: 1;
        opacity: 0;
        &:hover {
          opacity: 1;
        }

        .time {
          margin-left: 10px;
          font-size: 9px;
          color: #cecece;
        }
      }
      .expand {
        margin-left: auto;
      }
      .expand, .close {

        button {
          border: 0;
          background-color: transparent;
          cursor: pointer;
          padding: 5px 10px;
          border-radius: 5px;
          transform: translateY(-4px);

          &:hover {
            background-color: #dcdcdc;
          }
          &:active {
            //transform: scale(0.98);
          }
        }
        margin-right: 10px;
      }

    }
    #edit-area {
      margin-top: 35px; // 偏移出来头部的距离
      height: 200px;
      overflow: auto;
    }
    .textarea {
      font-family: "Microsoft YaHei";
      color: #666;
      margin-top: 35px; // 偏移出来头部的距离
      width: 100%;
      height: 200px;
      resize: none;
      font-size: 16px;
      border: 0;
      outline: none;
      //&:hover {
      //  border: 0;
      //  outline: none;
      //}
    }
    .vue-tags-input {

      width: 100%;
      max-width: 100%;
      border-bottom: 2px solid #cecece;
      :deep .ti-input {
        border: 0;
        //background-color: red;
      }
      :deep .ti-autocomplete {
        //background: #283944;
        border: 1px solid #8b9396;
        width: 500px;
        transform: translateX(-10px) translateY(4px);
        padding-bottom: 10px;
        padding-left: 10px;
        border-radius: 0 0 10px 10px;
        //transform: ;
        //border-top: none;
        border: 0;
        //display: flex;
        ul {
          display: flex;
          //background-color: red;
          .ti-item {
            //background-color: red;

          }
        }
      }
    }
    .colorTools {
      border-top: 1.5px solid rgba(178, 178, 178, 0.7);
      padding-top: 10px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      font-size: 10px;

      .colorSelect {
        z-index: 1;
        //border: 1px yellow solid;
        border: 0;
        margin: 0;
        width: 50px;
        padding: 0 10px;
        height: 25px;
        background-color: #dcdcdc;
        cursor: pointer;

        &.bg {
          padding-right: 2px;
          border-radius: 15px 0 0 15px;
        }
        &.text {
          padding-left: 2px;
          border-radius: 0 15px 15px 0;
        }
      }

    }
    // 颜色标签列表
    .colorList {
      //margin-left: 100px;
      //margin-top: 10px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .colorItem {
        display: inline-block;
        height: 20px;
        font-size: 10px;
        padding: 2px 10px;
        border-radius: 10px;
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
}

</style>
